import { graphql, Link } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import Meta from 'components/meta'
import Layout from 'components/layout'
import parse from 'html-react-parser'
import Disqus from 'disqus-react'
import './style.scss'

const BlogDetailPage = ({ data, location }) => {
  const blog = get(data, 'contentfulBlog')
  const relatedBlogs = get(data, 'allContentfulBlog.nodes')
  const disqusShortname = 'limitless-4' //found in your Disqus.com dashboard
  const disqusConfig = {
    url: location.origin + '/blog/' + blog.slug, //this.props.pageUrl
    identifier: blog.slug, //this.props.uniqueId
    title: blog.title, //this.props.title
  }
  const siteMetadata = {
    title: 'Limitless Learning | Limitless With Jess',
    description: 'Gatsby starter for bootstrap a blog',
    siteUrl: 'https://limitlesswithjess.com/limitless-learning/' + blog.slug,
  }
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} />
      <div className="wrapper inner-page">
        <div className="page-content cus-contact-location blog-detail-content">
          <section className="page-contact content-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-8  m-auto">
                  <div className="pr-md-cus-4">
                    <div className="conactUs-banner-wrapper blog-banner">
                      {blog.image ? (
                        <img
                          src={blog.image ? blog.image.file.url : ''}
                          alt={
                            blog.image && blog.image.description
                              ? blog.image.description
                              : ''
                          }
                        />
                      ) : (
                        ''
                      )}
                      {blog.videoUrl ? (
                        <iframe
                          height="400"
                          width="100%"
                          src={blog.videoUrl}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="blog-info mb-2 d-inline-block mt-3">
                      <h1 className="sub-heading mb-1">{blog.title}</h1>
                      <div className="timer mb-4 pl-1">
                        <i
                          className="fa fa-calendar lightgrey-color"
                          aria-hidden="true"
                        ></i>
                        <small className="pl-1 pr-4">{blog.updatedAt}</small>
                      </div>
                      {blog.longDescription
                        ? parse(blog.longDescription.childMarkdownRemark.html)
                        : ''}
                    </div>
                    <div className="d-flex flex-column pb-4 w-100">
                      <div className="post-share">
                        <span>Share:</span>
                        <a
                          target="_blank"
                          className="clearfix"
                          href={
                            'https://www.facebook.com/sharer/sharer.php?u=' +
                            location.origin +
                            '/limitless-learning/' +
                            blog.slug
                          }
                        >
                         <i className="feather-facebook mr-2"></i>
                          facebook
                        </a>
                        <a
                          target="_blank"
                          className="clearfix"
                          href={
                            'https://twitter.com/intent/tweet?text=' +
                            blog.title +
                            '&url=' +
                            location.origin +
                            '/limitless-learning/' +
                            blog.slug
                          }
                        >
                          {/* <i className="unicon-twitter"></i> */}
                          <i className="feather-twitter mr-2"></i>
                          twitter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                </div>

                {/* <div className="row row-comment row-comment-section">
                  <div className="col-md-12 section-comments">
                    <div className="container"> */}
                      <div className="row section-comments" >
                        <div className="col-md-8 m-auto ">
                          <div className="section-add-comment">
                            {/* <div className="comments">
                              <div className="comments-area"></div>
                            </div> */}
                            <div id="respond" className="comment-respond">
                              <div className="article-container">
                                <Disqus.DiscussionEmbed
                                  shortname={disqusShortname}
                                  config={disqusConfig}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    {/* </div>
                  </div>
                </div> */}

              <div className="row">
                <div className="col-12 mt-4 mb-5 border-top pt-5">
                  <h4 className="sub-heading">Related Posts</h4>
                  <div className="recent-post-blog-detail d-flex flex-align-start flex-column flex-md-row">
                    {relatedBlogs &&
                    relatedBlogs.length &&
                    relatedBlogs.length > 1
                      ? relatedBlogs.map((item, index) => {
                          if (item.slug != blog.slug) {
                            return (
                              <div
                                key={index}
                                className="related-post-img px-2 base-featured mr-3"
                              >
                                {item.image ? 
                                <Link to={'/limitless-learning/' + item.slug}>
                                  <img
                                    className="about-img img-responsive"
                                    src={
                                      item.image
                                        ? item.image.file.url + '?w=320'
                                        : ''
                                    }
                                    alt={
                                      item.image && item.image.description
                                        ? item.image.description
                                        : ''
                                    }
                                  />
                                  <h4 className="sub-heading mb-1 mt-3 text-overflow">{item.title}</h4>
                                </Link>
                                : ''}
                                 {item.videoUrl ? (
                                       <div> <iframe
                                          width="100%"
                                          src={item.videoUrl}
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                          allowFullScreen
                                        ></iframe>
                                        <Link to={'/limitless-learning/' + item.slug}>
                                        <h4 className="sub-heading mb-1 mt-3 text-overflow">{item.title}</h4>
                                      </Link>
                                      </div>
                                      ) : (
                                        ''
                                      )}
                              </div>
                            )
                          }
                        })
                      : 'No related post'}
                  </div>
                </div>
              </div>

            </div>
          </section>
          <div className="empty-space col-xs-b35 col-md-b70"></div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogDetailPage

export const query = graphql`
  query($slug: String, $tag: String) {
    contentfulBlog(slug: { eq: $slug }) {
      tag {
        tag
      }
      title
      slug
      id
      videoUrl
      category {
        name
      }
      longDescription {
        childMarkdownRemark {
          html
        }
      }
      shortDescription {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
        description
      }
      author
      updatedAt(formatString: "MMM Do YY")
      id
    }
    allContentfulBlog(filter: { tag: { tag: { eq: $tag } } }) {
      nodes {
        slug
        id
        title
        videoUrl
        tag {
          tag
        }
        image {
          file {
            url
          }
          description
        }
      }
    }
  }
`
